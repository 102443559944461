import {
  SET_CONFIGURATION,
  SET_ENTERPRISES,
  SET_CONFERENCES,
  SELECT_ENTERPRISE,
} from "../actions/Types";

let nullState = {
  configuration: null,
  enterprises: [],
  conferences: [],
  currentEnterprise: null,
};
export default (state = nullState, { payload, type }) => {
  switch (type) {
    case SET_CONFIGURATION:
      return {
        ...state,
        configuration: payload,
      };
    case SET_ENTERPRISES:
      return {
        ...state,
        enterprises: payload,
      };
    case SET_CONFERENCES:
      return {
        ...state,
        conferences: payload,
      };
    case SELECT_ENTERPRISE:
      return {
        ...state,
        currentEnterprise: payload,
      };
    default:
      return state;
  }
};
