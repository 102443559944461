import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_LOADING,
  CLEAR_LOADING,
} from "./Types";

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const dispatchMessage = (message) => async (dispatch) => {
  dispatch(setMessage(message));
};

export const clearMessage = () => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE });
};

export const setLoading = () => ({ type: SET_LOADING });

export const clearLoading = () => ({ type: CLEAR_LOADING });
