export const GET_USER = "GET_USER";
export const CREATE_USER_INFO = "CREATE_USER_INFO";
export const REGISTER_USER = "REGISTER_USER";
export const GET_USER_FROM_TOKEN = "GET_USER_FROM_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const UPLOAD_PROFILE = "UPLOAD_PROFILE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_GOGLE = "LOGIN_GOGLE";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SELECT = "UPDATE_USER_INFO_SELECT";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const EXIST_FILE_CV = "EXIST_FILE_CV";
export const CURRENT_INDEX_CANVAS = "CURRENT_INDEX_CANVAS";
export const GET_EMPLOYMENTS_BY_ENTERPRISE = "GET_EMPLOYMENTS_BY_ENTERPRISE";
export const LOADING_EMPLOYMENTS = "LOADING_EMPLOYMENTS"; // ON_LOADING, NOT_LOADING
export const UPDATE_SELECTIONUSER_MAIL = "UPDATE_SELECTIONUSER_MAIL";
export const VALIDATE_SELECTIONUSER_MAIL = "VALIDATE_SELECTIONUSER_MAIL";
/* Property */

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";

/* FAIR */
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const SELECT_ENTERPRISE = "SELECT_ENTERPRISE";
export const SET_ENTERPRISES = "SET_ENTERPRISES";
export const SET_CONFERENCES = "SET_CONFERENCES";

/* ENTEPRISE */
export const GET_JOBFAIR_ENTERPRISE = "GET_JOBFAIR_ENTERPRISE";
export const GET_JOBFAIR_CONFERENCES = "GET_JOBFAIR_CONFERENCES";
export const GET_PATH_LOCATION = "GET_PATH_LOCATION";
export const SELECTED_ENTERPRISE = "SELECTED_ENTERPRISE";
export const OPEN_SENDMESSAGE = "OPEN_SENDMESSAGE";