import axios from "axios";
import { baseUrl } from "../../constants";
import { setMessage } from "./Property";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  EXIST_FILE_CV,
  CREATE_USER_INFO,
  UPDATE_SELECTIONUSER_MAIL,
  VALIDATE_SELECTIONUSER_MAIL,
  GET_USER_INFO,
} from "./Types";
import { dnis_2da_esp } from "../../mocks/userDni";
import { dnis_pregrado } from "../../mocks/userDni";
import { codigos } from "../../mocks/userCode";

export const setPreloadedState = async () => {
  const response = await axios.get(baseUrl + "/api/users");
  let preloadedState = {};
  if (response.data.ok && response.data.user) {
    preloadedState = { user: { user: response.data.user } };
  }
  return preloadedState;
};

export const forgotPassword = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-pass",
    formData
  );
  console.log(response);
  console.log(formData);
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const forgotUser = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-user",
    formData
  );
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const loginUser =
  (formData, intentos, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(baseUrl + "/api/users/login", formData);
    //console.log("loginUser -> response", response);
    const { ok, message, user } = response.data;

    if (ok) {
      dispatch({ type: LOGIN_USER, payload: user });
      if (retorno) return user;
    } else {
      if (intentos <= 1) dispatch(setMessage(message));
      else
        dispatch(
          setMessage(
            "¿Tienes problemas para verificarte? Utiliza la opción Necesito Ayuda para solicitar soporte"
          )
        );
      if (retorno) return null;
    }
  };

export const registerUser =
  (formData, retorno = false, outRemainingTime) =>
  async (dispatch) => {
    const response = await axios.post(
      baseUrl + "/api/users/register",
      formData
    );

    const { user, ok, message } = response.data;
    if (ok) {
      if (outRemainingTime && outRemainingTime <= 0) {
        dispatch({ type: REGISTER_USER, payload: user });
        dispatch(setMessage("Bienvenido a la Feria Laboral!"));
      } else {
        dispatch(
          setMessage(
            "¡Gracias por registrarte!, ¡Esperanos! Pronto se abrirá la feria"
          )
        );
      }
      if (retorno) return user;
    } else {
      dispatch(setMessage(message));
      if (retorno) return null;
    }
  };

export const validateUser = (form) => (dispatch) => {
  try {
    let choice = form.choice;
    let flag = null;
    console.log(form);
    if (!form && !form.choice) {
      dispatch(setMessage("Debe de seleccionar un metodo validación"));
      return false;
    }
    if (!form.value || form.value.trim() === "") {
      dispatch(
        setMessage(
          "Debe de ingresar su " +
            (choice === "DNI" ? "documento de identidad" : "código")
        )
      );
      return false;
    } else {
      switch (choice) {
        case "DNI": {
          flag = dnis_pregrado.find((e) => e === form.value);
          if (!flag) {
            flag = dnis_2da_esp.find((e) => e === form.value);
          }
          break;
        }
        case "CODIGO": {
          flag = codigos.find((e) => e === form.value);
          break;
        }
        default:
          return;
      }
    }
    if (!flag)
      dispatch(
        setMessage(
          "No se ha encontrado su " +
            (choice === "DNI" ? "documento de identidad" : "código") +
            ", por favor verifique el dato ingresado"
        )
      );

    return flag !== null && flag !== undefined;
  } catch (e) {
    throw new Error("Error al encontrar estudiante");
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const response = await axios.delete(baseUrl + "/api/users");
    const { ok, message } = response.data;
    if (ok) {
      dispatch({ type: LOGOUT_USER });
    } else {
      // TODO:esto temporal hasta que se arregle lo de las cookies
      dispatch({ type: LOGOUT_USER });
      dispatch(setMessage(message));
    }
  } catch (error) {
    dispatch(setMessage(error.message));
    console.log("ERROR: -->", error);
  }
};

export const saveTodoStep = (name) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/users/steps/saveTodo", {
    step: name,
  });
  const { ok, message, data } = response.data;
  console.log(response);
  if (!ok) {
    dispatch(setMessage(message));
  }
};
export const getUserEmailVerifiedStatus = async (userId) => {
  try {
    const response = await axios.get(
      baseUrl + "/api/v2/verify-email/user/" + userId
    );
    return response.data;
    /*  const { ok, message, verified } = response.data;
    if (ok) {
      return verified;
    } else {
      dispatch(setMessage(message));
      return response;
    } */
  } catch (error) {
    /* dispatch(
      setMessage("Error al validar email verificado de usuario  " + error)
    ); */
    return { ok: false, message: "Error al verificar el estado del correo" };
    // throw error;
  }
};

export const createPostulacion =
  (formData, setOpen = () => {}) =>
  async (dispatch) => {
    const validateEmail = await getUserEmailVerifiedStatus(formData.author);
    if (validateEmail.ok) {
      console.log("createPostulacion -> formData", formData);
      const response = await axios.post(
        baseUrl + "/api/reclutamiento/postulacion",
        formData
      );
      const { ok, message, data } = response.data;
      if (ok) {
        dispatch(saveTodoStep("POSTULAR"));
        dispatch(
          setMessage(
            "Acaba de postular, este atento a las siguientes fases en Selection Latam"
          )
        );
      } else {
        dispatch(setMessage(message));
      }
      return ok;
    } else {
      setOpen(!validateEmail.ok);
      dispatch({
        type: VALIDATE_SELECTIONUSER_MAIL,
        payload: !validateEmail.ok,
      });
    }
  };

export const uploadCurriculumVitae = (file) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "/api/v2/files/uploads/curriculumVitae",
      file
    );
    console.log("uploadCurriculumVitae", response);
    if (response.data.ok) {
      dispatch(saveTodoStep("CV_COMPLETO"));
      dispatch(setMessage("Archivo CV subido satisfactoriamente."));
    } else {
      dispatch(setMessage(response.message));
    }
  } catch (error) {
    if (error.response) {
      dispatch(setMessage(`${error.response.data.message}`));
    } else {
      dispatch(setMessage("Error al subir CV, comuníquese con HCP"));
    }
  }
};

export const getUserInfo = () => async (dispatch) => {
  /**[TODO] */
  const response = await axios.get(baseUrl + "/api/users/info");
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch({ type: GET_USER_INFO, payload: data });
    return data;
  } else {
    dispatch({ type: "ENABLE_REACTOUR", payload: true });
    dispatch(setMessage(message));
  }
};

export const userRegExpress =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(
      baseUrl + "/api/v2/users/reg-express",
      formData
    );
    const { ok, message, data } = response.data;
    console.log("userRegExpress", response.data);
    if (ok) {
      dispatch({ type: CREATE_USER_INFO, payload: data });
      dispatch(setMessage("Se agrego tus datos correctamente"));
      if (retorno) return data;
    } else {
      dispatch(setMessage(message));
      if (retorno) return null;
    }
  };

export const existCV = (tipo) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/upload/existFile", tipo);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: EXIST_FILE_CV, payload: data });
    dispatch(setMessage(message));
  } else {
    dispatch(setMessage(message));
  }
};

export const changeUserSelectionEmail = (email) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/v2/users/selection/change-email",
    {
      email: email,
    }
  );
  const { ok, message } = response.data;
  if (ok) {
    dispatch({ type: UPDATE_SELECTIONUSER_MAIL, payload: email });
  }
  dispatch(setMessage(message));
  return ok;
};

export const sendEmailConfirmation = () => async (dispatch) => {
  const response = await axios.get(
    baseUrl + "/api/v2/users/selection/send-validation-email"
  );
  const { ok, message } = response.data;
  dispatch(setMessage(message));
  return ok;
};

export const setEmailValidation = (validate) => async (dispatch) => {
  dispatch({ type: VALIDATE_SELECTIONUSER_MAIL, payload: validate });
};
