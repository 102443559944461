import {
  GET_EMPLOYMENTS_BY_ENTERPRISE,
  LOADING_EMPLOYMENTS,
  GET_PATH_LOCATION,
} from "../actions/Types";

let nullState = {
  allEmployments: null,
  loadingEmployments: "NOT_LOADING",
  location: null,
};
export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_EMPLOYMENTS_BY_ENTERPRISE:
      return {
        ...state,
        allEmployments: payload,
      };
    case LOADING_EMPLOYMENTS:
      return {
        ...state,
        loadingEmployments: payload,
      };
    case GET_PATH_LOCATION:
      return {
        ...state,
        location: payload,
      };
    default:
      return state;
  }
};
