import {
  GET_USER,
  CREATE_USER_INFO,
  REGISTER_USER,
  GET_USER_FROM_TOKEN,
  UPDATE_USER,
  LOGIN_USER,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SELECT,
  LOGOUT_USER,
  GET_USER_INFO,
  EXIST_FILE_CV,
  CURRENT_INDEX_CANVAS,
  UPDATE_SELECTIONUSER_MAIL,
  VALIDATE_SELECTIONUSER_MAIL,
} from "../actions/Types";

let nullState = {
  user: {},
  notifications: [],
  userInfo: null,
  cv: null,
  cvDetails: null,
  profile: false,
  currentIndex: -1,
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case CREATE_USER_INFO:
    case GET_USER_INFO:
    case UPDATE_USER_INFO:
    case UPDATE_USER_INFO_SELECT:
      return {
        ...state,
        userInfo: payload,
      };
    case REGISTER_USER:
    case GET_USER_FROM_TOKEN:
    case UPDATE_USER:
    case LOGIN_USER:
    case GET_USER:
      return {
        ...state,
        user: payload,
      };
    case LOGOUT_USER:
      return nullState;
    case EXIST_FILE_CV:
      return {
        ...state,
        cv: payload,
      };
    case UPDATE_SELECTIONUSER_MAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: payload,
        },
      };
    case VALIDATE_SELECTIONUSER_MAIL:
      return {
        ...state,
        selectionUserMail: payload,
      };
    case CURRENT_INDEX_CANVAS:
      return {
        ...state,
        currentIndex: payload,
      };
    default:
      return state;
  }
};
