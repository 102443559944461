import { combineReducers } from "redux";
import user from "./User";
import property from "./Property";
import employment from "./Employment";
import fair from "./Fair";
import enterprise from "./Enterprise";
export default combineReducers({
  user,
  property,
  employment,
  fair,
  enterprise,
});
