import {
  GET_JOBFAIR_ENTERPRISE,
  GET_JOBFAIR_CONFERENCES,
  SELECTED_ENTERPRISE,
  OPEN_SENDMESSAGE
} from "../actions/Types";

let nullState = {
  fairEnterprise: null,
  fairConferences: [],
  fairEnterpriseEmployment: null,
  openMessage: false,
};
export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_JOBFAIR_CONFERENCES:
      return {
        ...state,
        fairConferences: payload,
      };
    case GET_JOBFAIR_ENTERPRISE:
      return {
        ...state,
        fairEnterprise: payload,
      };
    case OPEN_SENDMESSAGE: 
      return {
        ...state,
        openMessage: payload
      };
    case SELECTED_ENTERPRISE:
      return {
        ...state,
        fairEnterpriseEmployment: payload,
      };
    default:
      return state;
  }
};
